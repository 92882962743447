import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full'
  },
  // {
  //   path: '',
  //   component: AuthLayoutComponent,
  //   children: [
  //     {
  //       path: 'sessions',
  //       loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
  //       data: { title: 'Session'}
  //     }
  //   ]
  // },
  {path: 'signin',
  loadChildren: () => import('./views/signin-signup/signin-signup.module').then(m => m.SigninSignupModule),
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [],
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: { title: 'Others', breadcrumb: 'OTHERS'}
      },
      {
        path: 'home',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
        // data: { title: 'home', breadcrumb: 'HOME'}
      },
      {
        path: 'inbox',
        loadChildren: () => import('./views/inbox/inbox.module').then(m => m.InboxModule),
        // data: { title: 'Inbox', breadcrumb: 'INBOX'}
      },
      {
        path: 'search',
        loadChildren: () => import('./views/search-view/search-view.module').then(m => m.SearchViewModule)
      }
      
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

