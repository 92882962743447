
  <!-- <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>Shiba Inu</mat-card-title>
    <mat-card-subtitle>Dog Breed</mat-card-subtitle>
  </mat-card-header> -->
<div
fxLayout="column"
fxLayout.xs="column"
fxLayoutAlign="start stretch"
fxFlex="60"
fxFlex.xs="94"
fxFlex.sm="80">
<img mat-card-image class="card-img" src="../../../../assets/images/payment_link.svg" alt="payment">
<div>
  <p>
    The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
    A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
    bred for hunting.
  </p>
</div></div>
 
  <!-- <mat-card-actions>
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button>
  </mat-card-actions> -->
