<footer class="main-footer">
  <div class="container-dynamic d-flex">
    © 2021 Copyright HaiPals · <span (click)="gotoagreement()">Merchant Agreement</span> · Terms of Use · Privacy Policy ·
    <!-- <a
      mat-raised-button
      color="accent"
      href="http://1.envato.market/JN1r7"
      >Buy Egret</a
    >
    <span class="m-auto"></span>
    Design & Developed by <a href="https://ui-lib.com"> UI Lib</a> -->
  </div>
</footer>
